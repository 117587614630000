
<script>


    export let data;
    export let kedai_murah;
    export let metric;
    export let peringkat;
    // export let size;
    export let dbg;

    import * as d3 from 'd3';
    import d3comparator from "$lib/d3comparator.js";


    let containerWidth;
    let items = [];

    // $:  console.log('containerWidth',containerWidth)
    // $:  if (containerWidth) {

    //         let barW = 150;
    //         let itemsPerRow = Math.floor(containerWidth/barW);
    //         let numRows = ['sm'].includes(size) ? 5 : 3;
    //         // let totalItems = d3.min([20, numRows * itemsPerRow ]);
    //         let totalItems = numRows * itemsPerRow;

    //         items = kedai_murah.slice(0, totalItems)

    //     }

    $: items = kedai_murah.slice(0, 12)



    import {
        urlDebug,
        urlKedai,
        shortenWord
    } from "$lib/components/Utilities.svelte";

    import {
        PremiseCard,
        PremiseHargaRendah
    } from "$lib/components/Cards";

    import * as Card from "$lib/components/ui/card";


</script>


<!-- <div class="pre">
    <div>{size}</div>
    <div>{containerWidth}px</div>
</div> -->


<div class="flex justify-center items-stretch flex-wrap gap-1 p-0 m-0" bind:offsetWidth={containerWidth}>


    {#each items as d, i}

        <a
            href={ urlDebug(dbg, d.url || urlKedai(shortenWord(d.lookup_premise.premise), d.lookup_premise.premise_code)) }
            class="
                group
                flex-1
                min-w-[140px] max-w-[150px]
                rounded-tl-3xl
                rounded-tr-none
                rounded-bl-none
                rounded-br-none
                bg-[#427FED]
                hover:bg-[#532fa8]

                relative
            "
        >


                <div class="
                    rounded-tl-3xl
                    pl-3 pr-1
                    py-3
                    leading-3 text-left
                    bg-[#532fa8]
                    text-white
                "
                >
                    <PremiseHargaRendah {d} {metric} {peringkat}/>
                </div>

                <div class="
                    bg-[#427FED]
                    group-hover:bg-[#532fa8]
                    text-white
                    py-1
                    text-center

                    mb-10
                ">
                    <PremiseCard {data} {d}/>
                </div>

                <div class="
                    absolute
                    bottom-0
                    left-0
                    w-full
                    text-center
                    pb-2
                    pt-2

                    text-xs
                    text-white
                    leading-3
                    bg-[#0000ff40]
                ">
                    <address class="store_address leading-1">
                        {#if data?.districtid}
                            {@html d?.lookup_premise?.address||''}
                        {:else if data?.stateid}
                            {@html d?.lookup_premise?.district||''}
                        {:else}
                            {@html d?.lookup_premise?.district||''}, {@html d?.lookup_premise?.state||''}
                        {/if}
                    </address>
                </div>


                {#if dbg}
                    <div class="text-xs text-[black] text-right overflow-hidden">
                        {d.url}
                    </div>
                {/if}

        </a>



    {/each}
</div>